import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { AgChartsReact } from "ag-charts-react";

import "chota";
import "./styles.css";

export default function App() {
  const [text, setText] = useState("");
  const handleFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("loadend", (e) => {
      const result = e.target?.result;
      if (!result) return;
      setText(result.toString());
    });
    reader.readAsText(file);
  }, []);
  const [data, headers] = useMemo(() => {
    const lines = text.toString().split("\n");
    const [header, ...rows] = lines.map((l) => l.split(","));
    const entries = rows.map((row) =>
      header.slice(1).reduce((acc, col, index) => {
        if (Number.isNaN(parseInt(row[index + 1], 10))) return acc;

        return {
          ...acc,
          timestamp: new Date(row[0]),
          [col]: parseInt(row[index + 1], 10)
        };
      }, {})
    );
    console.log(entries);
    return [entries, header.slice(1)];
  }, [text]);
  return (
    <div className="app">
      <div
        style={{
          padding: "8px 12px"
        }}
      >
        <h1 style={{ margin: "5px 0 0 0" }}>Kilowatt Ride CSV Analyzer</h1>
      </div>
      <nav
        style={{
          padding: "10px",
          paddingBottom: "3px"
        }}
        className="bg-light"
      >
        <div>
          <div className="row"></div>
          <div className="row">
            <div className="col-12">
              <input
                className="button primary"
                type="file"
                accept="text/csv"
                onChange={handleFile}
              />
            </div>
          </div>
        </div>
      </nav>
      <div
        style={{
          flex: "1",
          width: "100%"
        }}
      >
        <AgChartsReact
          options={{
            type: "line",
            theme: "ag-default-dark",
            navigator: {
              enabled: true,
              min: 0.005
            },
            series: headers.map((h) => ({
              type: "line",
              xKey: "timestamp",
              yKey: h,
              yName: h,
              xName: "Time",
              strokeWidth: 1.25,
              marker: {
                enabled: false
              }
            })),
            axes: [
              {
                type: "time",
                position: "bottom"
              },
              {
                type: "number",
                position: "left"
              }
            ],
            data
          }}
        />
      </div>
    </div>
  );
}
